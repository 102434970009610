
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop } from 'vue-property-decorator';
import { Quicklink } from '@/models/quicklink';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

@Component
export default class ImageTextCard extends LoggedInComponentBase {
  @Prop() cardDetails!: Quicklink;
  @Prop({ default: false }) isWideSpotlight!: boolean;

  get relativeUrl() {
    return new URL(this.cardDetails.url).pathname;
  }

  get imageSrc() {
    let src = `${this.cardDetails.image || this.cardDetails.blobUrl}`;
    if (!this.isWideSpotlight) {
      src = cloudinaryImageResize(src, 400, 500);
    }
    return src;
  }

  public getElevation(hover: boolean) {
    if (this.$vuetify.breakpoint.xs) return 0;
    return hover ? 6 : 2;
  }
}


import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Route } from 'vue-router';
import {
  CalendarModule,
  CampaignModule,
  DashboardModule,
  PostModalModule,
} from '@/store';
import { getModule } from 'vuex-module-decorators';
import AssetCard from '@/components/cards/assetCard.vue';
import ImageTextCard from '@/components/cards/imageTextCard.vue';
import UpcomingPostsSidebar from '@/components/sidebars/upcomingPostsSidebar.vue';
import PostDetailSidebar from '@/components/sidebars/postDetailSidebar.vue';
import { EventTypesLabels } from '@/constants';
import { CalendarEvent } from '@/models/calendar/calendarEvent';
import { PostListItem } from '@/models/posts/postListItem';
import VideoCard from '@/components/cards/videoCard.vue';
import AuthoriseSocialChannelsModal from '@/components/modals/authoriseSocialChannelsModal.vue';
import { SidebarMixin } from '@/mixins/sidebarMixin';

const campaignModule = getModule(CampaignModule);
const calendarModule = getModule(CalendarModule);
const dashboardModule = getModule(DashboardModule);
const postModalModule = getModule(PostModalModule);

@Component({
  components: {
    AuthoriseSocialChannelsModal,
    VideoCard,
    ImageTextCard,
    AssetCard,
    UpcomingPostsSidebar,
    PostDetailSidebar,
  },
  mixins: [SidebarMixin],
})
export default class CreativeCentreIndex extends LoggedInComponentBase {
  public showQuickLinks: boolean = false;

  public showPostDetailSidebar: boolean = false;
  public showCategories: boolean = true;
  public selectedPost: PostListItem | null | undefined = null;

  public get campaignCategories(): object | null {
    return campaignModule.campaignCategories;
  }

  public get moduleUpcomingPosts() {
    return calendarModule.upcomingPosts;
  }

  public get quickLinks() {
    return dashboardModule.quickLinks;
  }

  public get recentAssets() {
    return dashboardModule.recentAssets;
  }

  public get currentStore() {
    return this.userModule.currentStore;
  }

  private get isPostUpdated(): boolean {
    return postModalModule.isPostUpdated;
  }

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: CreativeCentreIndex) => void)) => void
  ): Promise<void> {
    try {
      await campaignModule.getCampaignCategories();
      await dashboardModule.getDashboard();
      await calendarModule.getUpcomingPosts();
      next();
    } catch {
      next(false);
    }
  }

  private async created(): Promise<void> {
    await this.checkSocialChannelsAuthorised();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showEvent({
    nativeEvent,
    event,
  }: {
    nativeEvent: Event;
    event: CalendarEvent;
  }) {
    const eventType = event.eventType || event.postType;

    if (eventType === EventTypesLabels.Campaigns) {
      this.$router.push({
        name: 'creativecentre.campaignDetail',
        params: { category: event.categoryId, id: event.id },
      });
    } else {
      this.selectedPost = event;
      this.showPostDetailSidebar = true;
    }
    nativeEvent?.stopPropagation();
  }

  public async handlePostUpdated() {
    await calendarModule.getUpcomingPosts();
    this.showPostDetailSidebar = false;
  }

  @Watch('currentStore')
  public async onStoreChanged() {
    await dashboardModule.getDashboard();
    await campaignModule.getCampaignCategories();
    await calendarModule.getUpcomingPosts();

    this.showPostDetailSidebar = false;
    this.selectedPost = null;
  }

  @Watch('isPostUpdated')
  private async onPostUpdated(): Promise<void> {
    if (this.isPostUpdated) {
      await this.handlePostUpdated();
    }
  }
}
